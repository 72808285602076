<template>
  <b-container fluid class="p-5">
    <b-row class="my-5 d-flex justify-content-center">
      <b-col sm="8" align-v="center">
        <p style="line-height: 2">
          دليلك الشامل والمتكامل لكل المصارف العراقية والخدمات الرئيسية المقدمة
          من المصارف للأفراد والشركات واحدث الاخبار المالية
        </p>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col lg="4" md="6" v-for="item in list" :key="item.id" class="mb-3">
        <VBlog :item="item" :category="1"></VBlog>
      </b-col>
    </b-row>
    <b-pagination
      v-model="page"
      :current-page="meta.current_page"
      :per-page="meta.per_page"
      :total-rows="meta.last_page * meta.per_page"
    ></b-pagination>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
    };
  },
  watch: {
    page() {
      this.getAll();
    },
  },
  mounted() {
    this.getAll();
  },
  computed: {
    meta() {
      return this.$store.state.website.articles.meta;
    },
    list() {
      return this.$store.state.website.articles.list;
    },
  },
  methods: {
    getAll() {
      this.$store.dispatch("website/articles/get", this.page);
    },
  },
};
</script>

<style lang="scss" scoped>
.active .page-link {
  background-color: var(--info) !important;
  border-color: var(--info) !important;
}
</style>
